import React, { useEffect, useState } from 'react'

import { Alert, Flex, Spin } from 'antd';
// const contentStyle = {
//   padding: 50,
//   background: 'rgba(0, 0, 0, 0.05)',
//   borderRadius: 4,
// };
// const content = <div style={contentStyle} />;

function LoadingComponentAntD({content}) {
  return (
   <>
   <section className='loader-wrapper'>
    {/* <Spin tip="" size="large"></Spin> */}
    {/* <Spin tip="Loading">Loading</Spin> */}
    <Spin tip={content} size="large">
        {content}
      </Spin>
   </section>
    {/* <Spin spinning={spinning} fullscreen /> */}
   {/* <Flex gap="small" vertical>
    <Flex gap="small">
      <Spin tip="Loading" size="small">
        {content}
      </Spin>
      
        {content}
      </Spin>
    </Flex>
    <Spin tip="Loading...">
      <Alert
        message="Alert message title"
        description="Further details about the context of this alert."
        type="info"
      />
    </Spin>
  </Flex> */}
   </>
  )
}

export default LoadingComponentAntD