import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './apis/apiSlice';
// import authReducer from '../features/auth/authSlice';
import storage from "redux-persist/lib/storage";
import rootReducer from './rootReducer'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import userReducer from './userSlice';

// import authReducer, { AuthState } from './authSlice';
// import userReducer, { UserState } from './userSlice';
// export interface RootState {
//     auth: AuthState;
//     users: UserState;
// }

const persistConfig = {
    key: "phone_temple_mailer",
    version: 1,
    storage,
    whitelist: ["auth", "baseUrl"], // only auth
  };
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: {
//         auth: authReducer,
//         // users: userReducer,
//         [apiSlice.reducerPath]: apiSlice.reducer,


//     },
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
//     devTools: false
// });

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiSlice.middleware),
      devTools: process.env.NODE_ENV !== 'production',
  });
  
  export const persistor = persistStore(store);

