import './App.css';
// import React, { useEffect } from 'react';
import MailEditor from './features/mailer/MailEditor';
import { Routes, Route, useLocation } from "react-router-dom";
import NoPage from './pages/NoPage';
import AllEmailTemplates from './pages/AllEmailTemplates';
// import { useEffect } from 'react';
import RootLayout from './layout/RootLayout';
// import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setBaseUrl } from './store/slice/baseUrlSlice';
// import { setBaseUrl } from './features/auth/authSlice';
// import { useDispatch, useSelector } from 'react-redux';



function App() {
  
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const getBaseUrlFromReferrer = () => {
//       const referrer = document.referrer;
//       if (referrer) {
//         try {
//               return `${new URL(referrer).origin}/api/mailer/`;
//             } catch (error) {
//                 console.error('Invalid referrer URL:', error);
//             }
//         }
//         // Fallback to a default URL if referrer is not available or invalid
//         return '/api/mailer/';
//     };

//     const baseUrl = getBaseUrlFromReferrer();
//     if (baseUrl) {
//       dispatch(setBaseUrl(baseUrl));
//     }
// }, [dispatch]);


  // useEffect(() => {
  //     // Get the referrer URL
  //     if (!storeAuth?.baseUrl) {
  //       const previousURL = document.referrer;
  //       setReferrer(previousURL);
  //       console.log('Referrer:', previousURL);
  //       // dispatch(setBaseUrl(previousURL));
  //       dispatch(setBaseUrl('https://admin.phonetemple.site'))
  //     } 
  // }, [location,storeAuth]); 


  




  return (
    <>
    <div className="App">
      <Routes>
        <Route path='/:auth/:user_token/:viewType' 
          element={<RootLayout/>}>
          <Route index element={<AllEmailTemplates/>}/>
          <Route path='add' element={<MailEditor/>}/>
          <Route path='create-temp' element={<MailEditor/>}/>
          <Route path='edit/:tempId' element={<MailEditor updating={true}/>} />
        </Route>
        

        <Route path='*' element={<NoPage/>}/>
      </Routes>

    </div>
    </>
  );
}

export default App;
