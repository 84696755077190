import { createSlice } from "@reduxjs/toolkit";

export const siteInforSlice =createSlice({
    name: "siteInfoStore",
    initialState:{
        info: null
    },

    reducers:{
        setSiteInfor:(state,{payload})=>{
            state.info=payload;
        },
        clearSiteInfoSlice: (state,action)=>{
            state.info=null;
        }

    }
})

export const {setSiteInfor, clearSiteInfoSlice} = siteInforSlice.actions;
export default siteInforSlice.reducer;