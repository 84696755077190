import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import './FallBackUi.css'

function FallBackUI(props) {
const user_auth = useSelector(state=> state.auth)

  return (
   <>
     <section className="error-container">
            <h3>Oops! Something went wrong.</h3>
            <Link to={`/email-builder/${user_auth.authBearer}/${user_auth?.token}`} onClick={props.handleHideFallError}>
                Go to dashboard
            </Link>
            <h1 className="error-message">{props.error.message}</h1>
        </section>
   </>
  )
}

export default FallBackUI