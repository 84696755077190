import { Button, Layout, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { PHONE_TEMPLE_BASE_URL_IMG } from '../config';


const { Header } = Layout;
const NoPage = () => {
  const [logoImg, setLogoImg] = useState(null);
  const siteInfo = useSelector(state => state.siteInfoStore);
  useEffect(() => {
    if (siteInfo?.info) {
        if (siteInfo?.info?.status) {
            const settings = JSON.parse(siteInfo.info.panel.settings);
            setLogoImg(settings.company_logo);
        }
    }
}, [siteInfo]);
  const navigate = useNavigate();
  const user = useSelector(state=> state.auth);

  
  const backToHome= () => {
    navigate(`/${user?.authBearer}/${user?.token}`)
  }
  
  return (
    <>
    <Header style={{ display: 'flex', alignItems: 'center' }}>
                <div className="demo-logo" style={{ width: 'auto', height: '90%' }}>
                {logoImg &&
                    <img src={`${PHONE_TEMPLE_BASE_URL_IMG}${logoImg}`} style={{ width: 'auto', height: '100%' }} alt="logo" />
                    
                  }
                </div>
                {/* <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    items={items}
                    style={{ flex: 1, minWidth: 0 }}
                /> */}
            </Header>
      <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button onClick={backToHome} type="primary">Back To Home</Button>}
  />
    </>
  )
}

export default NoPage