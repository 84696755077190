import { combineReducers } from "@reduxjs/toolkit";
import apiSlice from './apis/apiSlice';
import authReducer from '../features/auth/authSlice';
import siteInfoReducer from "./slice/siteInfoSlice";
import baseUrlReducer from './slice/baseUrlSlice'


const rootReducer = combineReducers({
    auth: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    siteInfoStore: siteInfoReducer,
    baseUrl: baseUrlReducer,
});

export default rootReducer;

