import React, { useState } from 'react'
import { Button, Layout, Avatar, Space, message } from 'antd'
import { useSelector } from 'react-redux';
import LoadingComponentAntD from '../loadingCompAntd/LoadingComponentAntD';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { PHONE_TEMPLE_BASE_URL_IMG } from '../../config';

const { Header } = Layout;
function Navbar() {
    const siteInfo = useSelector(state => state.siteInfoStore)
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [logoImg, setLogoImg] = useState(null);
    const [profileImgUrl, setProfileImgUrl] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();


    const errorMsg = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
            className: 'toastify-custom-class',
            duration: 1000,
        });
    };

    useEffect(() => {
        if (siteInfo?.info) {

            // console.log(siteInfo.info)
            if (siteInfo?.info?.status) {
                setLoading(false);
                const settings = JSON.parse(siteInfo.info.panel.settings);

                setLogoImg(settings.company_logo)
                setProfileImgUrl(siteInfo.info.user.operator_profile_image);

            }
            if (!siteInfo?.info.status) {
                setLoading(false);
                errorMsg(siteInfo?.info?.message)

                // window.location.replace(siteInfo?.info?.url);
            }
        }
    }, [siteInfo]);

    // const navigateBackToDashboard = () => {
    //     setLoading(true);
    //     // window.location.replace(siteInfo.info.back_url)
    // }

    const backTohome = () => {
        navigate(`/${user?.authBearer}/${user?.token}`);
    }
    
    

    return (
        <>
            {contextHolder}
            {loading &&
                <LoadingComponentAntD content="Loading" />
            }
            {!user?.viewType &&

            <Header className='header-wrapper'>
                {/* <div className='container'>

            </div> */}

                {/* <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    items={items}
                    style={{ flex: 1, minWidth: 0 }}
                /> */}
                <div className='module-name-wrapper'>
                    {siteInfo.info &&
                        <>
                            <Button size='large' className='color-light' type='link' href={`${siteInfo?.info?.back_url}`}
                            // onClick={navigateBackToDashboard}
                            ><div className='icon-wrapper-custom'> <ArrowLeftOutlined className='header-icon-custom' /></div>
                                <h2> {siteInfo?.info?.module_name}</h2>
                            </Button>

                        </>
                    }
                </div>
                <div onClick={backTohome} className="logo-wrapper" >
                    {logoImg &&
                        <img src={`${PHONE_TEMPLE_BASE_URL_IMG}${logoImg}`} style={{ width: 'auto', height: '100%' }} alt="logo" />
                    }
                </div>

                {siteInfo.info &&
                    <div className='nav-actions'>

                        <h2 className='op-name'>
                            {/* <img src={siteInfo.info} alt="profile" /> */}
                            {siteInfo.info.user?.nick_name}</h2>

                    </div>

                }
            </Header>
            }
        </>
    )
}

export default Navbar