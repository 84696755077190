import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { Outlet } from 'react-router-dom'
// import { useLazyGetSiteInfoQuery } from '../features/mailer/mailerApiSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { setSiteInfor } from '../store/slice/siteInfoSlice';


function RootLayout() {
  // const [getSiteInfo] = useLazyGetSiteInfoQuery();
  // const dispatch = useDispatch();
  // const storeAuth = useSelector(state=>state.auth)
  // console.log(user_token,"----",auth)
  // const storeBaseUrl = useSelector(state=>state.baseUrl)
  // const [isBaseUrl,setIsBaseUrl] = useState(false);

  // useEffect(() => {
  // if (storeBaseUrl.value) {
  //   setIsBaseUrl(true);
  // }
  // }, [storeBaseUrl.value])




 
  return (
    <>
      <Navbar/>
      <Outlet/>      
    </>
    
  )
}

export default RootLayout